/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'

import Header from "./header"
import ogImage from '../images/og-image.png'
import "../scss/main.scss"

const Layout = ({ children }) => {

  return (
    <>
        <Helmet>
          <title>IDA sound residency 2020</title>
          <meta property="og:url" content="https://residency.idaidaida.net" />
          <meta property="og:title" content="IDA sound residency 2020" />
          <meta property="og:description" content="IDA Radio is happy to announce an open call for a collaborative sound residency for musicians, music producers and sound artists based in Estonia. " />
          <meta property="og:image" content={ogImage} />
      </Helmet>
      <Header />
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
