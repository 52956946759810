import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Accordion from "../components/Accordion";


const IndexPage = ({data}) => (
  <Layout>
    <div className="container">
      <div className="text">
        <p className="intro">IDA Radio is happy to announce the residents for the collaborative sound residency taking place in October 2020 – Vul Vulpes, Pavel Zionov & Mikk Madisson will be spending time & making music together in VLND Villa. Robert Nikolajev will be taking care of the studio setup & recording.</p>
          <p class="centered">Residents</p>
          <Accordion allowMultipleOpen>
            <div label="Vul Vulpes">
              <Img fluid={data.vv.childImageSharp.fluid} />
              <p>
              Vul Vulpes encounters influences from the fringes of electronic music, which she associates with field recordings, searching for new sound narratives and connections between different sound environments. At the moment, her creative focus is on sound collages and vocal improvisations. In 2016 she released her debut EP 'Fusion of Horizons' that was distributed world wide and gained international attention. Vul Vulpes has previously improvised on stage with Ratkiller, Algorütmid, Shift Tides and others.<br/><br/>
                <a target="_blank" rel="noreferrer" href="https://soundcloud.com/vulvulpes">Soundcloud</a>
              </p>
            </div>
            <div label="Pavel Zionov">
              <Img fluid={data.doom.childImageSharp.fluid} />
              <p>
              A member of Tallinn based experimental band Keetai, that has recently gone through metamorphosis from noisy punk to a mix of heavy drone guitar, crispy analogue sound and groovy industrial electronics. Pavel's workflow builds around inspiration from monumentality and esoteric movements all mixed with punk attitude.<br/><br/>
                <a target="_blank" rel="noreferrer" href="https://soundcloud.com/keetai">Soundcloud</a>
              </p>
            </div>
            <div label="Mikk Madisson">
              <Img fluid={data.mikk.childImageSharp.fluid} />
              <p>
              Mikk Madisson is a visual artist, writer and musician based in Berlin. Madisson has composed and mixed music for films, performances and written about the importance of sound and listening within algorithmic mass surveillance. His engagement with the sonic domain is driven by its capacity to provide the listener with visual input and visceral sensation.<br/><br/>
                <a target="_blank" rel="noreferrer" href="https://vimeo.com/396309507">Vimeo</a>
                <a target="_blank" rel="noreferrer" href="https://soundcloud.com/pageant">Soundcloud</a>
              </p>
            </div>
          </Accordion>
          <p class="centered">Residency</p>
          <Accordion allowMultipleOpen>
          <div label="About">
            <p>
              This is a collaborative residency, meaning that we will select two artists who will work together for one week in September 2020. The residency will take place in the city of Viljandi at VLND Villa. There are no restrictions or obligations, but we expect the artists to spend some time together in the studio, share their process and possible outcome at the end of the residency – a new piece of music, performance or something completely different in the field of sound-art. We encourage professionals as well as emerging artists to apply. 
            </p>
          </div>
          <div label="Timeline">
              <div className="timeline">
                <h3>16.10–23.10</h3>IDA Sound Residency in Viljandi<br/>
                <h3>24.10</h3>Residency presentation in Narva<br/>
                <h3>25.10</h3>Residency presentation in Lasnamägi<br/>
              </div>
          </div>
          <div label="Studio and recording">
            <p>
              A studio space with basic equipment will be covered by the organisers & a sound engineer will be on site for the whole period. We encourage you to bring along any equipment of your choice, pick up & transportation will be provided if necessary.
            </p>
          </div>
          <div label="IDA Radio">
            <Img fluid={data.ida.childImageSharp.fluid} />
            <p>
              IDA Raadio is an independent online community radio based in the city centre of Tallinn, Estonia. Founded May 1-st 2018 it connects interesting & innovating artists from Estonia & neighbouring countries. Hosting shows from 10AM to 10PM five days a week, IDA Raadio offers a unique platform for its nearly 200 radio show hosts. IDA's music programme consists of a wide range of genres (from classical, jazz & folk to various forms of modern contemporary dance music), plus educating talk-shows from local art institutions, progressive activists and writers. IDA Raadio is being run by a group of volunteers & aims to enrich & challenge the local scenes of media & culture. In March 2020, IDA Helsinki opened up it's doors & airwaves. </p>

            <p>Listen: <a target="_blank" rel="noreferrer" href="https://www.idaidaida.net">idaidaida.net</a></p>
          </div>
          <div label="VLND Villa">
            <Img fluid={data.vlnd.childImageSharp.fluid} />
            <p>VLND Villa is a cultural hub situated in the city of Viljandi. The villa has had many different functions over the years, from being a retirement home to hosting funeral table's, but it has mainly worked as a community bar. The venue has a big auditorium, idyllic porch & inner yard.</p>

            <p>See & read more: <a target="_blank" rel="noreferrer" href="https://www.facebook.com/vlndvilla/">Facebook</a></p>
          </div>
          <div label="Viljandi">
            <Img fluid={data.viljandi.childImageSharp.fluid} />
            <p>
              The beautiful town of Viljandi is geographically located between two major Estonian cities, Pärnu & Tartu. The town was first mentioned in 1283, upon being granted its town charter by Wilhelm von Endorpe. The once influential Estonian newspaper Sakala was founded in Viljandi in 1878. Viljandi has its own culture academy & annual folk festival Viljandi Folk.  
            </p>
          </div>
        </Accordion>
      </div>

      <footer>
          <p><a target="_blank" rel="noreferrer" href="https://residency2019.idaidaida.net/">IDA x NART Sound Residency 2019</a></p>

          <p>This project is carried out in cooperation with Cultural Endowment of Estonia, City of Viljandi and British Council in Estonia as part of
          the People to People Cultural Engagement Programme. Find more info about British
          Council’s work in Estonia at <a target="_blank" rel="noreferrer" href="https://www.britishcouncil.ee">britishcouncil.ee</a></p>
      </footer>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    ida: file(relativePath: { eq: "ida-raadio.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    viljandi: file(relativePath: { eq: "viljandi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vlnd: file(relativePath: { eq: "vlnd-villa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mikk: file(relativePath: { eq: "mikk.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vv: file(relativePath: { eq: "vv.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    doom: file(relativePath: { eq: "doom.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`