import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const { onClick, props: { isOpen, label } } = this;

    return (
      <div className="accordion">
        <div className={isOpen ? "accordion__label opened" : "accordion__label"} onClick={onClick} style={{ cursor: "pointer" }}>
          <h2>{label}</h2>
        </div>
        {isOpen && (
          <div className="accordion__content">
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;
